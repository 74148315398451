import { COOKIE_LOCALE_NAME, DEFAULT_LOCALE } from "@/constants/i18n";
import {
  RefreshAuthTokensDocument,
  RefreshAuthTokensMutation,
  RefreshAuthTokensMutationVariables,
} from "@/data-access/mutation/__generated__/refreshAuthTokens";
import { createMiddlewareApolloClient } from "@/lib/apollo/server";
import { getCookie } from "cookies-next";
import { GetServerSidePropsContext } from "next";
import { NextRequest, NextResponse } from "next/server";
import { getAccessTokenAmplify } from "./getAccessTokenAmplify";
import { logger } from "./logger";
import { getAuthTokensFromResponse } from "./tokenUtils";

export const AUTH_COOKIES = {
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  ID_TOKEN: "idToken",
  RESERVATION_ID: "reservationId",
} as const;

export const USER_ROLES = {
  USER: "user",
  TEMPORARY: "temporary",
  UNCONFIRMED: "user-unconfirmed",
} as const;

export type Role = (typeof USER_ROLES)[keyof typeof USER_ROLES];

export type SessionState = {
  isValid?: boolean;
  roles?: Role[];
};

export type User = {
  firstName?: string;
  lastName?: string;
  id?: string;
};

export type Session = SessionState & {
  user: User | null;
};

interface GetAccessTokenParams {
  req?: GetServerSidePropsContext["req"];
  res?: GetServerSidePropsContext["res"];
}

export const getAccessToken = async ({ req, res }: GetAccessTokenParams) => {
  let accessToken = null;
  let amplifyAccessToken = null;

  if (res && typeof res.getHeaders === "function") {
    const headers = res.getHeaders();
    const tokens = getAuthTokensFromResponse(headers);
    if (tokens && tokens.accessToken) {
      accessToken = tokens.accessToken;
    }
  }

  if (!accessToken && req && req.cookies) {
    accessToken = req.cookies.accessToken;
  }

  if (!accessToken && typeof window !== "undefined") {
    accessToken = getCookie(AUTH_COOKIES.ACCESS_TOKEN);
  }

  amplifyAccessToken = await getAccessTokenAmplify({ req, res });

  return amplifyAccessToken || accessToken;
};

interface RefreshTokenParams {
  req: NextRequest;
  res: NextResponse;
}

export const getRefreshedToken = async ({ req, res }: RefreshTokenParams) => {
  try {
    const locale = req.cookies.get(COOKIE_LOCALE_NAME)?.value || DEFAULT_LOCALE;
    const currentRefreshToken = req.cookies.get(AUTH_COOKIES.REFRESH_TOKEN)?.value;

    if (!currentRefreshToken) {
      throw new Error("No refresh token found");
    }

    const apolloClient = createMiddlewareApolloClient({ locale, req, res });
    const result = await apolloClient.mutate<RefreshAuthTokensMutation, RefreshAuthTokensMutationVariables>({
      mutation: RefreshAuthTokensDocument,
      variables: { refreshToken: currentRefreshToken },
    });

    if (!result?.data?.refreshLogin) {
      throw new Error("No data received");
    }

    const { accessToken, expiresIn, idToken, refreshToken } = result.data.refreshLogin;

    return {
      accessToken,
      idToken,
      expiresIn,
      refreshToken,
    };
  } catch (error) {
    logger(error);
    return null;
  }
};
