import BreakpointIndicator from "@/components/BreakpointIndicator";
import ErrorTemplate from "@/components/ErrorTemplate";
import { isProductionBuild } from "@/constants/common";
import { DEFAULT_LOCALE, I18N_NAMESPACES } from "@/constants/i18n";
import AnalyticsScreenView from "@/features/analytics/components/AnalyticsScreenView";
import { useApollo } from "@/lib/apollo/hook";
import { QueryClientProvider } from "@/providers/QueryClientProvider";
import { SessionProvider } from "@/providers/SessionProvider";
import { Session } from "@/utils/authUtils";
import { extractAndConvertCookiesFromHeaders, parseCookies } from "@/utils/cookieUtils";
import { getDateFnsLocaleFromLocaleString } from "@/utils/dateUtils";
import { logger } from "@/utils/logger";
import { validateToken } from "@/utils/tokenUtils";
import { ApolloProvider } from "@apollo/client";
import { GoogleTagManager } from "@next/third-parties/google";
import { ErrorBoundary } from "@sentry/nextjs";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Amplify } from "aws-amplify";
import { setDefaultOptions } from "date-fns";
import { InferGetServerSidePropsType, NextPage } from "next";
import { appWithTranslation, useTranslation } from "next-i18next";
import { PagesProgressBar as ProgressBar } from "next-nprogress-bar";
import { ThemeProvider } from "next-themes";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import localFont from "next/font/local";
import { useRouter } from "next/router";
import { z } from "zod";
import { makeZodI18nMap } from "zod-i18n-map";
import { NuqsAdapter } from "nuqs/adapters/next/pages";
import outputs from "../../amplify_outputs.json";
import nextI18NextConfig from "../../next-i18next.config";
import "./styles.css";

Amplify.configure(outputs, { ssr: true });

Amplify.configure({
  ...Amplify.getConfig(),
  Predictions: outputs.custom.Predictions,
});

const urbanGrotesk = localFont({
  src: [
    {
      path: "../../fonts/UrbanGrotesk/UrbanGroteskReBo-Bold2.ttf",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../fonts/UrbanGrotesk/UrbanGroteskLiSe-Bold2.ttf",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../fonts/UrbanGrotesk/UrbanGroteskReBo-Regular2.ttf",
      weight: "400",
      style: "normal",
    },
  ],
  fallback: ["sans-serif"],
  preload: true,
  display: "fallback",
  variable: "--font-primary",
});

export type NextPageWithLayout<Props extends (args: any) => any> = NextPage<InferGetServerSidePropsType<Props>> & {
  getLayout?: (page: React.ReactElement, props: InferGetServerSidePropsType<Props>) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout<any>;
  session: Session;
};

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID ?? "GTM-ML68K9FF";

function MyApp({ Component, pageProps, session }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const { t } = useTranslation();

  const currentLanguage = router.locale ?? DEFAULT_LOCALE;
  const apolloClient = useApollo(pageProps, currentLanguage);

  setDefaultOptions({
    locale: getDateFnsLocaleFromLocaleString(currentLanguage),
  });

  z.setErrorMap(makeZodI18nMap({ t, handlePath: { ns: I18N_NAMESPACES.ZOD } }));

  return (
    <SessionProvider initialSessionState={session}>
      <ApolloProvider client={apolloClient}>
        <QueryClientProvider>
          <>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>
              {`
                html {
                  font-family: ${urbanGrotesk.style.fontFamily};
                }
              `}
            </style>
          </>
          <AnalyticsScreenView />
          {getLayout(
            <ThemeProvider attribute="class" defaultTheme="light">
              <main className="app flex flex-grow flex-col antialiased">
                <ErrorBoundary fallback={<ErrorTemplate title={t("error.serverError.label")} />}>
                  <NuqsAdapter>
                    <Component {...pageProps} />
                    {isProductionBuild && <GoogleTagManager gtmId={GTM_ID} />}
                  </NuqsAdapter>
                </ErrorBoundary>
                <BreakpointIndicator />
                <ProgressBar height="4px" color="#3E4860" options={{ showSpinner: false }} shallowRouting />
                <ReactQueryDevtools initialIsOpen={false} />
              </main>
            </ThemeProvider>,
            pageProps,
          )}
        </QueryClientProvider>
      </ApolloProvider>
    </SessionProvider>
  );
}

interface Cookies {
  accessToken?: string;
  idToken?: string;
}

MyApp.getInitialProps = async (context: AppContext) => {
  const { req, res } = context.ctx;
  let cookies: Cookies = {};

  if (res) {
    const headers = res.getHeaders();
    const responseCookies = extractAndConvertCookiesFromHeaders(headers);
    cookies = { ...cookies, ...responseCookies };
  }

  if (!cookies.accessToken && req) {
    cookies = { ...cookies, ...parseCookies(req.headers.cookie) };
  }

  let session: Session = {
    isValid: false,
    roles: [],
    user: null,
  };

  if (cookies.accessToken) {
    const tokenResponse = await validateToken(cookies.accessToken);

    session.isValid = tokenResponse.isValid;
    session.roles = tokenResponse.roles;

    if (cookies.idToken) {
      try {
        const decodedIdToken = await validateToken(cookies.idToken);
        session = {
          ...session,
          user: {
            ...decodedIdToken.user,
          },
        };
      } catch (error) {
        logger(error);
      }
    }
  }

  const ctx = await App.getInitialProps(context);
  return {
    ...ctx,
    session,
  };
};

export default appWithTranslation(MyApp, nextI18NextConfig);
